import { Box } from "@mantine/core";
import Lottie from "lottie-react";
import dynamic from "next/dynamic";
import calendar from "../assets/lottie_monthly.json";
import performance from "../assets/lottie_performance.json";
// import time_management from "../assets/lottie_time-management.json";
import competition from "../assets/lottie_working-man.json";
import time_management from "../assets/lottie_clock.json";
import target from "../assets/lottie_target.json";
import mobile from "../assets/lottie_mobile.json";
import premium from "../assets/lottie-premium.json";
import heart from "../assets/lottie-heart-with-particles.json";
import confettilr from "../assets/lottie-confetti-lft-rgt.json";
import successful from "../assets/lottie-successful.json";
import ladder from "../assets/lottie-ladder2.json";
import speed from "../assets/lottie-speedy.json";
import happy from "../assets/lottie-happy.json";
import write from "../assets/lottie-writing.json";
// const DynamicLottie = dynamic(() => import("lottie-react"));

const LottieComponent = ({
    datakey,
    width = 50,
    top = 0,
    left = 0,
    position,
    loop = true,
}) => {
    let map = {
        calendar,
        performance,
        time_management,
        competition,
        target,
        mobile,
        premium,
        heart,
        confettilr,
        successful,
        ladder,
        speed,
        happy,
        write,
    };
    let data = map[datakey];
    return (
        <Box
            sx={{
                position,
                top,
                left,
                width,
                height: width,
                borderRadius: "100%",
                // opacity: 0.2,
            }}
        >
            <Lottie animationData={data} loop={loop} />
        </Box>
    );
};

export default LottieComponent;
